import { Label, Paragraph } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { LuggageOptions } from '@AuroraTypes';

export const WITH_LARGE_CABIN_BAG = 'WITH_LARGE_CABIN_BAG';
export const NO_LIMIT_CABIN_WEIGHT = 'NO_LIMIT';
export const NO_DIMENSION = 'NO_DIMENSION';

export const LuggageDescription: React.FC<{
  luggageOptions: LuggageOptions;
}> = ({ luggageOptions }) => {
  const { t } = useTranslation();
  const { cabinBagsDetails, mode, luggageDetails } = luggageOptions;

  let luggageDescription: JSX.Element[] = [];

  const { availableBags, weight } = luggageDetails ?? {};

  let includedDescriptionLabel =
    mode === 'INCLUDED' && t('checkout.freeBaggageDescription', { availableBags, weight });

  if (cabinBagsDetails) {
    const {
      underseatBagIsIncluded,
      underseatBagWeight,
      underseatBagDimensions,
      largeCabinBagIsIncluded,
      largeCabinBagAllowance,
      largeCabinBagWeight,
      largeCabinBagDimensions,
    } = cabinBagsDetails;

    const cabinLuggage = [
      {
        weight: underseatBagWeight,
        dimension: underseatBagDimensions,
        isIncluded: underseatBagIsIncluded,
        allowance: 1,
        descriptionKey: 'checkout.underseatBagsDescription',
        withLargeCabinBag: underseatBagIsIncluded && largeCabinBagIsIncluded,
      },
      {
        weight: largeCabinBagWeight,
        dimension: largeCabinBagDimensions,
        isIncluded: largeCabinBagIsIncluded,
        allowance: largeCabinBagAllowance,
        descriptionKey: 'checkout.cabinBagsDescription',
      },
    ]
      .map(
        (
          { isIncluded, allowance, weight, dimension, descriptionKey, withLargeCabinBag },
          index,
        ) => {
          let context = '';
          if (withLargeCabinBag) {
            context += WITH_LARGE_CABIN_BAG;
          }
          if (weight === NO_LIMIT_CABIN_WEIGHT) {
            context += context ? `_${NO_LIMIT_CABIN_WEIGHT}` : NO_LIMIT_CABIN_WEIGHT;
          }
          if (!dimension) {
            context += context ? `_${NO_DIMENSION}` : NO_DIMENSION;
          }

          return (
            isIncluded &&
            weight && (
              <Paragraph
                key={index}
                variant="small"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  color: 'textDimmedheavy',
                }}
              >
                <span sx={{ paddingRight: '3xs' }}>
                  {t(descriptionKey, {
                    count: allowance,
                    bagWeight: weight,
                    bagDimensions: dimension,
                    context,
                  })}
                </span>
              </Paragraph>
            )
          );
        },
      )
      .filter(Boolean);

    luggageDescription = luggageDescription.concat(cabinLuggage as any);
  }

  if (includedDescriptionLabel) {
    luggageDescription.push(
      <Paragraph
        variant="small"
        sx={{
          color: 'textDimmedheavy',
        }}
      >
        {includedDescriptionLabel}
      </Paragraph>,
    );
  }

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Label
        variant="mediumbold"
        sx={{
          marginBottom: '2xs',
        }}
      >
        {t('checkout.baggageTitle')}
      </Label>

      <ul
        sx={{
          listStyle: 'disc',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: ['l', 'xl'],
        }}
      >
        {luggageDescription.map((description, index) => (
          <li key={index}>{description}</li>
        ))}
      </ul>
    </div>
  );
};
