import { SetState } from 'zustand';

import { extractIncludedLuggageTypes } from './utils';
import {
  AncillaryProduct,
  AncillaryType,
  AssistedStatus,
  BasketFlight,
  BasketHotel,
  FullOrder,
  LeadPassengerDetails,
  LuggageOptions,
  LuggageProduct,
  OrderState,
  PartyConfiguration,
  PassengerDetails,
  PaymentProvider,
  RoomOptions,
} from '@AuroraTypes';
import { LuggageControlType } from '@Components/Checkout/LuggageOptions/LuggageOptions';
import { PaymentEngineState } from '@Components/Checkout/Payment/PaymentEngine';
import { createStore, Store } from '@Core/createStore';
import { removeFunctionMembers } from '@Core/utils';

export interface OrderStore {
  status: 'refreshing' | 'ok' | 'error' | 'loading' | 'booked';
  marginStatus: 'refreshing' | 'ok';
  voucherState: 'error' | 'empty' | 'applied' | 'applying' | 'unsupported';
  paymentEngineState: PaymentEngineState;
  paymentProvidersState: 'ok' | 'error' | 'fetching';
  paymentPlanState: 'synced' | 'syncing' | 'error';
  luggageOptionsStatus: 'fetching' | 'ok' | 'error';

  order: FullOrder;
  paymentProviders: PaymentProvider[];
  luggageOptions: LuggageOptions | undefined;
  roomOptions: RoomOptions;
  // payment token provided in the SearchParams, as when the payment provider redirects to us
  paymentTokenFromUrl: string | undefined;
  acknowledgedAssistedStatus: string | undefined;

  setStatus: (status: OrderStore['status']) => void;
  setOrder: (order: FullOrder) => void;
  setOrderState: (state: OrderState) => void;
  setRoomOptions: (roomOptions: RoomOptions) => void;
  setLuggageOptions: (luggageOptions: LuggageOptions) => void;
  setPaymentProviders: (paymentProviders: PaymentProvider[]) => void;
  hasFullCostVoucher: () => boolean;
  setPaymentEngineState: (paymentEngineState: PaymentEngineState) => void;
  setAcknowledgedAssistedStatus: (assistedStatus?: AssistedStatus[]) => void;
  setPartnerRedirectUrl: (url: string) => void;

  getAncillary: (ancillaryType: AncillaryType) => AncillaryProduct | undefined;
  getAncillaries: () => AncillaryProduct[];
  getFlight: () => BasketFlight | undefined;
  getHotel: () => BasketHotel | undefined;
  getRoomConfiguration: () => PartyConfiguration[];
  getLuggage: () => LuggageProduct | undefined;
  getIncludedLuggageTypes: () => { cabinLuggageIncluded?: boolean; holdLuggageIncluded?: boolean };
  getLuggageControlType: () => LuggageControlType;

  getLeadPassenger: () => LeadPassengerDetails | undefined;
  getNonLeadPassengers: () => PassengerDetails[];
  isAdultOnly: () => boolean;
  getFirstLowDepositPaymentAmount: () => number | undefined;
  getTotalParty: () => { adults: number; children: number };

  getPaymentProvider: (providerName: string) => PaymentProvider | undefined;
  getPaymentCardNetworks: (providerName: string) => string[];
  getPaymentProviderToken: (providerName: string) => string | undefined;

  setInitialValues: (overrides?: Partial<OrderStore>) => void;
}

const reducer =
  <T>(set: SetState<OrderStore>, fieldName: keyof OrderStore) =>
  (value: T) =>
    set((state: OrderStore) => ({ ...state, [fieldName]: value }));

export const ORDER_TIMEOUT_IN_MINUTES = 30;
export const createOrderStore = (initialValues?: Partial<OrderStore>): Store<OrderStore> =>
  createStore<OrderStore>(
    (set, get) => ({
      status: 'error',
      marginStatus: 'ok',
      voucherState: 'empty',
      order: {
        shortRef: '',
        bookingRef: '',
        state: 'QUICK_COST',
        ancillaries: [],
        basket: {
          ancillaries: [],
          hotel: null as any,
          flight: null as any,
        },
        // Important to set this to Infinity so that we know the order is not stale, but hasn't loaded yet
        costingTimestamp: Infinity,
        timeoutInMinutes: ORDER_TIMEOUT_IN_MINUTES,
        paymentPlans: [],
        pricing: {
          currency: '',
          currencyIcon: '',
          flight: 0,
          hotel: 0,
          perPerson: 0,
          showDiscount: false,
          total: 0,
          totalIncludingPaidAtHotel: 0,
          totalPaidAtHotel: 0,
          totalPaidToUs: 0,
          margin: 0,
          originalTotal: 0,
          roomFeePaidAtHotel: 0,
          roomTaxPaidAtHotel: 0,
          displayedPricingItems: [],
        },
        urls: {
          pandaUrl: '',
          srpUrl: '',
        },
        cancellationTerms: {},
        assistedStatus: [],
        inlineTermsAndConditions: [],
        priceBreakdownVisibility: 'NEVER',
        importantInfoLinks: [],
        bookCtaTermsAndConditions: {
          content: undefined,
        },
      },
      luggageOptions: undefined,
      luggageOptionsStatus: 'fetching',
      roomOptions: {
        boardBasis: [],
        parties: [],
      },
      paymentProviders: [],
      paymentEngineState: 'no-set',
      paymentPlanState: 'syncing',
      paymentProvidersState: 'fetching',
      paymentTokenFromUrl: undefined,
      acknowledgedAssistedStatus: undefined,
      ...initialValues,

      // setters
      setStatus: reducer(set, 'status'),
      setOrder: reducer(set, 'order'),
      setRoomOptions: reducer(set, 'roomOptions'),
      setLuggageOptions: reducer(set, 'luggageOptions'),
      setPaymentProviders: reducer(set, 'paymentProviders'),
      setPaymentEngineState: reducer(set, 'paymentEngineState'),
      setAcknowledgedAssistedStatus: (acknowledgedAssistedStatus) => {
        set({
          acknowledgedAssistedStatus: JSON.stringify(acknowledgedAssistedStatus),
        });
      },

      // getters
      getAncillary: (ancillaryType: AncillaryType) => {
        const state = get();
        const { basket } = state.order;

        return basket.ancillaries.find(({ type }) => type === ancillaryType);
      },
      getAncillaries: () => {
        const state = get();
        const { order, getAncillary } = state;

        const { ancillaries } = order;

        return ancillaries
          .map((ancillaryType) => getAncillary(ancillaryType))
          .filter(Boolean) as AncillaryProduct[];
      },
      getFlight: () => {
        const state = get();
        const { basket } = state.order;

        return basket.flight;
      },
      getHotel: () => {
        const state = get();
        const { basket } = state.order;

        return basket?.hotel;
      },
      getLuggage: () => {
        const state = get();
        const { basket } = state.order;

        return basket.ancillaries.find(({ type }) => type === 'LUGGAGE') as LuggageProduct;
      },
      getIncludedLuggageTypes: () => {
        const { luggageOptions } = get();
        if (!luggageOptions) {
          return {};
        }

        return extractIncludedLuggageTypes(luggageOptions);
      },
      getLuggageControlType: () => {
        const { luggageOptions } = get();

        if (!luggageOptions) {
          return LuggageControlType.NONE;
        }

        const { mode, luggageDetails } = luggageOptions;
        const { perParty } = luggageDetails ?? {};
        const showLuggageControls = mode && !['INCLUDED', 'NOT_AVAILABLE'].includes(mode);

        if (!showLuggageControls) {
          return LuggageControlType.NONE;
        }

        return perParty ? LuggageControlType.ALL : LuggageControlType.INDIVIDUAL;
      },
      getRoomConfiguration: () => {
        const hotelProduct = get().getHotel();

        return (hotelProduct?.rooms || []).map(({ partyConfiguration }) => partyConfiguration);
      },
      getLeadPassenger: (): LeadPassengerDetails | undefined => {
        const state = get();

        return state.order.party?.leadPassenger;
      },
      getNonLeadPassengers: (): PassengerDetails[] => {
        const state = get();

        if (!state.order.party) {
          return [];
        }

        return state.order.party.passengers.slice(1);
      },
      isAdultOnly: (): boolean => {
        const state = get();

        const hotel = state.getHotel();
        if (!hotel) {
          return false;
        }

        return hotel.rooms.every(
          ({ partyConfiguration: { childAges, infants } }) =>
            childAges.length === 0 && infants === 0,
        );
      },
      getFirstLowDepositPaymentAmount: (): number | undefined => {
        const state = get();

        const lowDeposit = state.order.paymentPlans.find(({ id }) => id === 'LOW_DEPOSIT');

        return lowDeposit?.payments?.[0].amount;
      },
      getTotalParty: (): { adults: number; children: number } => {
        const state = get();

        const totalParty = state.getHotel()?.rooms.reduce(
          (acc, curr) => {
            acc.adults += curr.partyConfiguration.adults;
            acc.children += curr.partyConfiguration.childAges.length ?? 0;
            acc.children += curr.partyConfiguration.infants ?? 0;

            return acc;
          },
          { adults: 0, children: 0 },
        );

        return totalParty ?? { adults: 0, children: 0 };
      },
      getPaymentProvider: (providerName: string) => {
        const { paymentProviders } = get();

        return paymentProviders.find(({ processorName }) => processorName === providerName);
      },
      getPaymentProviderToken: (providerName: string) => {
        const state = get();

        // we priorisize the `clientToken` passed in the url, in order to support the redirect flow
        // https://primer.io/docs/payment-methods/handle-redirects-and-deep-links
        if (state.paymentTokenFromUrl) {
          return state.paymentTokenFromUrl;
        }

        const paymentProvider = state.getPaymentProvider(providerName);
        if (!paymentProvider) {
          return;
        }

        return paymentProvider.clientToken;
      },
      getPaymentCardNetworks: (providerName: string) => {
        const pm = get().getPaymentProvider(providerName);
        if (!pm) {
          return [];
        }

        return pm.paymentMethods.find((m) => m.methodName === 'PAYMENT_CARD')?.cardNetworks || [];
      },
      setInitialValues: (overrides?: Partial<OrderStore>) => {
        const extended = createOrderStore(overrides).getState();
        set(removeFunctionMembers(extended) as OrderStore);
      },
      hasFullCostVoucher: () => {
        return !!get().paymentProviders.find(({ processorName }) => processorName === 'VOUCHER');
      },
      setOrderState: (newState: OrderState) => {
        const state = get();

        set({
          ...state,
          order: {
            ...state.order,
            state: newState,
          },
        });
      },
      setPartnerRedirectUrl: (url: string) => {
        const state = get();

        set({
          ...state,
          order: {
            ...state.order,
            externalRedirectUrl: url,
          },
        });
      },
    }),
    'OrderStore',
  );
